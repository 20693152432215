<template>
  <v-chip
    :color="color"
    label
  >
    {{ title }}
  </v-chip>
</template>

<script>
  export default {
    name: 'LittleChip',
    props: {
      color: {},
      title: {},
    },
  }
</script>

<style scoped>

</style>
