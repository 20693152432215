<template>
  <v-card
    class="d-flex flex-column"
    elevation="0"
    outlined
  >
    <div
      class="d-flex align-center justify-center px-16 py-2"
      style="background-color: #BDBDBD"
    >
      <div
        class="text-h3"
      >
        {{ $t('dishesDetail') }}: {{ productInfo.name }}
      </div>
      <v-spacer/>
      <v-btn icon>
        <v-icon large>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div class="d-flex mt-4 px-16">
      <div>
        <div class="d-flex flex-column justify-center align-center mt-4">
          <v-img
            :src="productInfo.images[0]"
            max-width="300px"
          />
        </div>
      </div>
      <div
        class="ml-8"
        style="min-width: 800px"
      >
        <div class="d-flex align-center">
          <little-chip
            :color="'primary lighten-4 black--text'"
            :title="'Aaden Pos'"
          />
          <div class="ml-4">
            {{ $t('MadeByInnerken') }} {{ productInfo.name }}
          </div>
        </div>
        <div
          class="d-flex px-2 py-2"
          style="background-color: #F06292"
        >
          <v-icon
            dark
            large
          >
            mdi-alarm-check
          </v-icon>
          <div
            class="ml-2 mt-1 text-h4"
            style="color: white"
          >
            {{ $t('AadenStore') }}
          </div>
        </div>
        <div
          class="d-flex px-2 py-2 flex-column mb-10"
          style="background-color: #E0E0E0"
        >
          <div class="d-flex align-center">
            <div
              class="text--secondary"
              style="min-width: 50px"
            >
              {{ $t('price') }}:
            </div>
            <div
              class="text-h3"
              style="color: #EF5350"
            >
              {{ productInfo.realPrice.unit_amount / 100 |priceDisplay }}
            </div>
          </div>
          <div class="d-flex mt-4">
            <div
              class="text--secondary"
              style="min-width: 50px"
            >
              {{ $t('description') }}:
            </div>
            <div>{{ productInfo.description }}</div>
          </div>
          <div class="d-flex mt-4">
            <div
              class="text--secondary"
              style="min-width: 50px"
            >
              {{ $t('annotation') }}:
            </div>
            <div>{{ $t('LimitToOneSHop') }} <span class="font-weight-bold">{{ productInfo.maxCount }}</span> {{ $t('Equipment') }}</div>
          </div>
          <div
            class="mt-4 d-flex align-center"
            style="margin-left: 50px"
          >
            <v-icon large>
              mdi-minus-circle
            </v-icon>
            <div class="text-h3 mx-2 mb-1">
              {{ productInfo.count }}
            </div>
            <v-icon large>
              mdi-plus-circle
            </v-icon>
          </div>
          <v-btn
            class="primary lighten-2 mt-4"
            elevation="0"
            outlined
          >
            {{ $t('YourFirstOrder') }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import PageHeader from '@/composable/InK/typography/PageHeader'
  import LittleChip from '@/composable/littleChip'

  export default {
    name: 'ProductDetailInfo',
    components: {
      LittleChip,
      PageHeader,
    },
    props: {
      productInfo: {},
    },
    data: function () {
      return {
        imagesList: null,
      }
    },
    computed: {
      getProductImagesList () {
        return this.productInfo.images
      },
    },
  }
</script>

<style scoped>

</style>
